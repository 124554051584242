import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { isValidDateString } from "utilities/dateTime";
import { DayPicker } from "react-day-picker";
import {
  getDisableMatcher,
  getParsedDateValue,
  getValidDateFromFormatedString,
} from "./helpers";
import { de } from "react-day-picker/locale";
import Button from "../../../forms/Button";
import { DatePickerBaseProps, LOCALE } from "./DatePicker";
import Select from "../../Select";

const START_YEAR = 1900;
const END_YEAR = 2150;

interface DatePickerSingleProps extends DatePickerBaseProps {
  showTodayButton?: boolean;
  onChange?: (value: string) => void;
  value?: string;
}

export const DatePickerSingle = ({
  onChange,
  showTodayButton,
  locale,
  value,
  rangeStart,
  rangeEnd,
  today,
  endMonth,
  startMonth,
}: DatePickerSingleProps) => {
  const { t } = useTranslation();
  const [month, setMonth] = useState<Date>();

  const todayDate = useMemo<Date>(
    () => isValidDateString(today) || new Date(),
    [today]
  );

  useEffect(() => {
    setMonth(isValidDateString(value) || todayDate);
  }, [value, todayDate]);

  const monthOptions = useMemo(() => {
    return Array.from({ length: 12 }, (_, i) =>
      new Intl.DateTimeFormat(locale, { month: "long" }).format(
        new Date(2000, i)
      )
    ).map((label, i) => ({
      value: String(i),
      label,
    }));
  }, [locale]);

  const yearOptions = useMemo(() => {
    return Array(END_YEAR - START_YEAR + 1)
      .fill(0)
      .map((v, i) => {
        const option = String(START_YEAR + i);
        return {
          value: option,
          label: option,
        };
      });
  }, []);

  return (
    <DayPicker
      data-testid="day-picker"
      mode="single"
      month={month}
      onMonthChange={setMonth}
      selected={getValidDateFromFormatedString(value)}
      className="date-picker"
      onSelect={(newDate) => {
        newDate &&
          onChange?.(getParsedDateValue("YYYY-MM-DD", String(newDate)));
        newDate && setMonth(newDate);
      }}
      disabled={getDisableMatcher({ rangeStart, rangeEnd, todayDate })}
      captionLayout="dropdown"
      startMonth={startMonth}
      endMonth={endMonth}
      weekStartsOn={1}
      locale={locale ? LOCALE[locale] : de}
      today={todayDate}
      components={{
        YearsDropdown: () => {
          return (
            <div>
              <Select
                nobox
                compact
                isClearable={false}
                value={month ? String(month?.getFullYear()) : ""}
                onChange={(newYearValue) => {
                  const newDate = month?.setFullYear(Number(newYearValue));
                  setMonth(new Date(newDate || ""));
                }}
                options={yearOptions}
                renderOption={(option) => (
                  <span className="tw-font-extralight tw-text-cs-shade-black">
                    {option.label}
                  </span>
                )}
              />
            </div>
          );
        },
        MonthsDropdown: () => {
          return (
            <div>
              <Select
                nobox
                compact
                isClearable={false}
                value={month ? String(month?.getMonth()) : ""}
                onChange={(newMonthValue) => {
                  const newDate = month?.setMonth(Number(newMonthValue));

                  setMonth(new Date(newDate || ""));
                }}
                options={monthOptions}
                renderOption={(option) => (
                  <span className="tw-font-extralight tw-text-cs-shade-black">
                    {option.label}
                  </span>
                )}
              />
            </div>
          );
        },
      }}
      footer={
        showTodayButton && (
          <Button
            isSecondary
            onClick={() => {
              setMonth(todayDate);
            }}
          >
            {t("Calendar.today")}
          </Button>
        )
      }
    />
  );
};
