import { isValidDateString } from "utilities/dateTime";
import moment from "moment/moment";
import { DateRange } from "./DatePicker";

// the earliest default date
// we use this value if the start range date is unknown
// in the day picker library, if the end date given, the start date have to be defined
export const EARLIEST_START_DATE = new Date(Date.UTC(1900, 0, 1));

export const getDisableMatcher = ({
  rangeStart,
  rangeEnd,
}: {
  rangeStart?: string;
  rangeEnd?: string;
  todayDate?: Date;
} = {}) => {
  const rangeStartDate = isValidDateString(rangeStart);
  const rangeEndDate = isValidDateString(rangeEnd);

  if (rangeStartDate && rangeEndDate) {
    return { before: rangeStartDate, after: rangeEndDate };
  }

  if (rangeStartDate) {
    return { before: rangeStartDate };
  }

  if (rangeEndDate) {
    return { after: rangeEndDate };
  }
  // default all dates available
  return false;
};

export const getParsedDateValue = (
  format: "DD.MM.YYYY" | "YYYY-MM-DD",
  value?: string
) => {
  if (!value) {
    return "";
  }

  if (format === "DD.MM.YYYY" && value === String(EARLIEST_START_DATE)) {
    return "";
  }

  if (isValidDateFormat(value)) {
    if (format === "DD.MM.YYYY") {
      return value;
    }

    const date = new Date(value.split(".").reverse().join("-"));
    return date.toISOString().split("T")[0];
  }

  return moment(new Date(value)).isValid()
    ? moment(new Date(value)).format(format)
    : value;
};

export const isValidDateFormat = (dateString: string) => {
  const regex =
    /^(0[1-9]|[12]\d|3[01])\.(0[1-9]|1[0-2])\.(19[0-9]{2}|20[0-9]{2}|21[0-4][0-9]|2150)$/;
  return regex.test(dateString);
};

export const isDifferentDate = (date1?: Date, date2?: Date) => {
  if (!date1 && !date2) return false;
  if (!date1 || !date2) return true;
  const normalized1 = date1.setHours(0, 0, 0, 0);
  const normalized2 = date2.setHours(0, 0, 0, 0);
  return normalized1 !== normalized2;
};

interface GetUpdatedValueProps {
  currentValue: DateRange;
  newValue: DateRange;
}

export const getUpdatedValue = ({
  currentValue,
  newValue,
}: GetUpdatedValueProps): {
  key: "from" | "to" | undefined;
  value: Date | undefined;
} => {
  if (!currentValue && !newValue) return { key: undefined, value: undefined };

  if (isDifferentDate(newValue?.from, currentValue?.from)) {
    return { key: "from", value: newValue?.from };
  }

  if (isDifferentDate(newValue?.from, currentValue?.to)) {
    return { key: "to", value: newValue?.to };
  }

  if (isDifferentDate(newValue?.to, currentValue?.from)) {
    return { key: "from", value: newValue?.from };
  }

  if (isDifferentDate(newValue?.to, currentValue?.to)) {
    return { key: "to", value: newValue?.to };
  }

  return { key: undefined, value: undefined };
};

export const getValidDateFromFormatedString = (dateString?: string) => {
  if (!dateString) {
    return undefined;
  }

  // Handle DD.MM.YYYY format specifically
  if (isValidDateFormat(dateString)) {
    const dateParts = dateString.split(".");
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // JS months are 0-indexed (0-11)
    const year = parseInt(dateParts[2], 10);

    const date = new Date(year, month, day);

    // Verify the date is valid by checking if the components match after construction
    if (
      date.getFullYear() === year &&
      date.getMonth() === month &&
      date.getDate() === day
    ) {
      return date;
    }
    return undefined;
  } else {
    // Fallback for other formats (like ISO dates)
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? undefined : date;
  }
};
