import React from "react";
import { DayPicker } from "react-day-picker";
import { de } from "react-day-picker/locale";
import { DatePickerBaseProps, DateRange, LOCALE } from "./DatePicker";

interface DatePickerRangeProps extends DatePickerBaseProps {
  onChange?: (value?: DateRange) => void;
  value?: DateRange;
}

export const DatePickerRange = React.memo(
  ({ onChange, locale, value }: DatePickerRangeProps) => {
    return (
      <DayPicker
        data-testid="day-picker-range"
        mode="range"
        selected={value as DateRange}
        className="date-picker-range"
        onSelect={(newDate) => {
          onChange?.(newDate as DateRange);
        }}
        defaultMonth={(value as DateRange)?.from}
        numberOfMonths={2}
        weekStartsOn={1}
        locale={locale ? LOCALE[locale] : de}
      />
    );
  }
);
