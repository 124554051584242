import { useEffect, RefObject, useState } from "react";
import { DateRange } from "../DatePicker/DatePicker";
import { OnChangeRange, OnChangeSingle } from "../DatePickerFieldElem";
import FieldContainerInterface from "../FieldContainerInterface";
import {
  EARLIEST_START_DATE,
  getParsedDateValue,
  getUpdatedValue,
  getValidDateFromFormatedString,
  isDifferentDate,
  isValidDateFormat,
} from "../DatePicker/helpers";
import { isCorrectTimeLine } from "utilities/dateTime";

export type CalendarState = "" | "start" | "end";

interface UseDatePickerDialogProps
  extends Pick<FieldContainerInterface, "onBlur"> {
  containerRef: RefObject<HTMLDivElement>;
  dialogRef: RefObject<HTMLDivElement>;
  onChange?: OnChangeSingle | OnChangeRange;
  setEndValue: (value: string) => void;
  setStartValue: (value: string) => void;
  value?: string | DateRange;
}

export const useDatePickerDialog = ({
  containerRef,
  dialogRef,
  onChange,
  onBlur,
  setStartValue,
  setEndValue,
  value,
}: UseDatePickerDialogProps) => {
  const [calendarState, setCalendarState] = useState<CalendarState>("");
  const [endRangeFocus, setEndRangeFocus] = useState<boolean>(false);
  const [startRangeFocus, setStartRangeFocus] = useState<boolean>(false);

  // Handle clicks outside the dialog
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Element;
      const isInsideSelectMenu = target.closest(".Select__menu-list") !== null;

      if (
        containerRef.current &&
        !containerRef.current.contains(target as Node) &&
        dialogRef.current &&
        !dialogRef.current.contains(target as Node) &&
        !isInsideSelectMenu
      ) {
        setCalendarState("");
      }
      endRangeFocus && setEndRangeFocus(false);
      startRangeFocus && setStartRangeFocus(false);
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [containerRef, dialogRef]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const adjustDialogPosition = () => {
      const dialog = dialogRef.current;
      if (!dialog) return;

      // Get dialog dimensions
      const { x } = dialog.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const widthUi = 710;
      if (x + widthUi > viewportWidth) {
        dialog.style.right = "0";
      }
    };

    adjustDialogPosition();
  }, [dialogRef, calendarState]);

  useEffect(() => {
    const startField: HTMLElement | null = document.querySelector(
      ".date-picker-field-elem-range-start input"
    );
    const endField: HTMLElement | null = document.querySelector(
      ".date-picker-field-elem-range-end input"
    );
    if (startField && calendarState === "start") {
      startField.focus();
    }
    if (endField && calendarState === "end") {
      endField.focus();
    }
  }, [calendarState]);

  const onChangeSingle = (newDate?: string) => {
    (onChange as OnChangeSingle)?.(
      getParsedDateValue("YYYY-MM-DD", (newDate as string) || "")
    );
    setEndValue(getParsedDateValue("DD.MM.YYYY", (newDate as string) || ""));
    setCalendarState("");
    onBlur?.(getParsedDateValue("YYYY-MM-DD", (newDate as string) || ""));
  };

  const onChangeRange = (newDate?: DateRange) => {
    const to = (newDate as DateRange)?.to;
    const from = (newDate as DateRange)?.from;
    const toCurrent = (value as DateRange)?.to;
    const fromCurrent = (value as DateRange)?.from;
    const valueToUpdate = getUpdatedValue({
      currentValue: value as DateRange,
      newValue: newDate as DateRange,
    });
    const isSameValueClick = !isDifferentDate(to, from);

    if (calendarState === "end") {
      // end date is in focus
      // handle edge cases first
      switch (true) {
        case !toCurrent && !fromCurrent:
          // the new date in the future
          (onChange as OnChangeRange)?.({
            from: EARLIEST_START_DATE,
            to: valueToUpdate.value,
          });

          setEndValue(
            getParsedDateValue("DD.MM.YYYY", String(valueToUpdate.value || ""))
          );

          setCalendarState("start");
          setStartRangeFocus(true);
          break;

        case isSameValueClick:
          setCalendarState("");
          break;

        case valueToUpdate.key === "to":
          (onChange as OnChangeRange)?.({
            from: fromCurrent,
            to: valueToUpdate.value,
          });

          setEndValue(
            getParsedDateValue("DD.MM.YYYY", String(valueToUpdate.value || ""))
          );

          setCalendarState("");
          break;

        case valueToUpdate.key === "from":
          (onChange as OnChangeRange)?.({
            from: valueToUpdate.value,
            to: undefined,
          });
          break;
      }
    } else {
      // start date is in focus
      const isStartEmpty = !isDifferentDate(
        (value as DateRange).from,
        EARLIEST_START_DATE
      );
      // handle edge cases first
      switch (true) {
        // end date was given first
        // new start date is later than the already given end date
        case isStartEmpty &&
          valueToUpdate.key === "to" &&
          isCorrectTimeLine({
            start: toCurrent,
            end: to,
          }):
          (onChange as OnChangeRange)?.({
            from: valueToUpdate.value,
            to: undefined,
          });
          setStartValue(
            getParsedDateValue("DD.MM.YYYY", String(toCurrent || ""))
          );
          setCalendarState("end");
          setEndRangeFocus(true);
          break;

        // end date was given first
        // new start date is earlier than the already given end date
        case isStartEmpty && valueToUpdate.key === "to":
          (onChange as OnChangeRange)?.({
            from: valueToUpdate.value,
            to: toCurrent,
          });
          setStartValue(
            getParsedDateValue("DD.MM.YYYY", String(valueToUpdate.value || ""))
          );
          setCalendarState("");
          break;

        // regular start date update
        case !!newDate:
          (onChange as OnChangeRange)?.({
            from: valueToUpdate.value,
            to: isCorrectTimeLine({
              start: valueToUpdate.value,
              end: toCurrent,
            })
              ? toCurrent
              : undefined,
          });
          setStartValue(
            getParsedDateValue("DD.MM.YYYY", String(valueToUpdate.value || ""))
          );
          setCalendarState("end");
          setEndRangeFocus(true);
          break;
      }
    }
  };

  const removeValueSingle = () => {
    (onChange as OnChangeSingle)?.(undefined);
    setEndValue("");
  };

  const removeValueRange = () => {
    (onChange as OnChangeRange)?.({
      from: undefined,
      to: undefined,
    });
    setEndValue("");
    setStartValue("");
  };

  const onRangeStartBlur = (newDate: string) => {
    const currentStartDisplayValue = getParsedDateValue(
      "DD.MM.YYYY",
      (value as DateRange)?.from?.toString()
    );

    if (
      (newDate !== currentStartDisplayValue && isValidDateFormat(newDate)) ||
      !newDate
    ) {
      (onChange as OnChangeRange)?.({
        to: (value as DateRange)?.to,
        from: newDate
          ? new Date(getParsedDateValue("YYYY-MM-DD", newDate))
          : EARLIEST_START_DATE,
      });
    }
    setStartValue(newDate);
  };

  const onSingleEndChange = (newDate: string) => {
    (onChange as OnChangeSingle)?.(
      getParsedDateValue("YYYY-MM-DD", newDate || "")
    );
    setEndValue(newDate);
    setCalendarState("");
  };

  const onSingleEndBlur = (newDate: string) => {
    (onChange as OnChangeSingle)?.(
      getParsedDateValue("YYYY-MM-DD", newDate || "")
    );
    setEndValue(newDate);
  };

  const onRangeEndBlur = (newDate: string) => {
    const currentDisplayValue = getParsedDateValue(
      "DD.MM.YYYY",
      (value as DateRange)?.from?.toString()
    );

    if (
      (newDate !== currentDisplayValue && isValidDateFormat(newDate)) ||
      !newDate
    ) {
      (onChange as OnChangeRange)?.({
        from: (value as DateRange)?.from,
        to: getValidDateFromFormatedString(newDate),
      });
      setEndValue(newDate);
    }
  };

  const getStartValueDisplay = (from?: Date) => {
    if (isDifferentDate(from, EARLIEST_START_DATE)) {
      return getParsedDateValue("DD.MM.YYYY", String(from || ""));
    }
    return "";
  };

  return {
    onSingleEndBlur,
    onRangeEndBlur,
    onSingleEndChange,
    onRangeStartBlur,
    removeValueSingle,
    removeValueRange,
    calendarState,
    setCalendarState,
    onChangeSingle,
    onChangeRange,
    getStartValueDisplay,
  };
};
