export const isValidDateString = (dateString?: string) => {
  if (!dateString) {
    return undefined;
  }
  const date = new Date(dateString);

  return isNaN(date.getTime()) ? undefined : date;
};

export const isValidDate = (date?: Date) => {
  if (!date) {
    return undefined;
  }

  return isNaN(date.getTime()) ? undefined : date;
};

/**
 * Compares the day section of the date to check if the date is in the past
 * @param startDate Date to check if it is in the past
 * @param includeToday If true, the function will return true if the date is today
 * @returns true if the day of the provided date is not today
 */
export const isDateInThePast = (
  startDate?: Date | string,
  includeToday = false
) => {
  if (!startDate) {
    return false;
  }

  const date = new Date(startDate);
  const now = new Date();

  if (includeToday) {
    return date.setHours(0, 0, 0, 0) < now.setHours(23, 59, 59, 999);
  }

  return date.setHours(0, 0, 0, 0) < now.setHours(0, 0, 0, 0);
};

interface GetDayProps {
  today?: Date;
  offset?: number;
}

export const getFirstMonthDay = ({ today, offset = 0 }: GetDayProps) => {
  const date = today || new Date();

  if (offset) {
    date.setMonth(date.getMonth() + offset);
  }

  date.setDate(1);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const getLastDayOfMonth = ({ today, offset = 0 }: GetDayProps) => {
  const date = today || new Date();
  date.setMonth(date.getMonth() + 1 + offset);
  date.setDate(0);
  date.setHours(23, 59, 59, 999);

  return date;
};

export const getCurrentWeekRange = ({ today }: { today?: Date } = {}) => {
  const date = today || new Date();
  const start = new Date(date);
  const end = new Date(date);

  const dayOfWeek = date.getDay(); // 0 (Sunday) to 6 (Saturday)

  const offsetToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
  start.setDate(date.getDate() + offsetToMonday);
  start.setHours(0, 0, 0, 0);

  // End of the week (Sunday)
  const endDate = new Date(start);
  endDate.setDate(endDate.getDate() + 6);
  end.setTime(endDate.getTime());
  end.setHours(23, 59, 59, 999);

  return { start, end };
};

export const formatDateAPI = (date?: Date | null) => {
  if (!date) {
    return "";
  }

  const pad = (num: number) => num.toString().padStart(2, "0");
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const setEndOfTheDay = (date: Date) => {
  const newDate = date.setHours(23, 59, 59, 999);
  return new Date(newDate);
};

export const isCorrectTimeLine = ({
  start,
  end,
}: {
  start?: Date;
  end?: Date;
}) => {
  if (!start || !end) {
    return false;
  }
  return start.getTime() < end.getTime();
};
